import axios from 'axios';
import store from '@/store'

const baseUrl = '/api/v1/client'

class HostServiceClass {
  async loadHosts() {
    try {
      const {data} = await axios.get('/api/host/all')
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async saveHost(payload) {
    try {
      const {data} = await axios.post('/api/host/save', payload)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async loadUserToHost(host_id) {
    if (!host_id) {
      host_id = store.state.admin.host_id
    }

    try {
      const {data} = await axios.get(`/api/host/${host_id}/users`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async createUser(host_id, payload) {
    try {
      const {data} = await axios.post(`/api/host/${host_id}/user/register`, payload);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateUser(host_id, payload) {
    try {
      const {data} = await axios.post(`/api/host/${host_id}/user`, payload);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


  async addRoleToUser(host_id, user_id, payload) {
    try {
      const {data} = await axios.post(`/api/host/${host_id}/user/${user_id}/addRole`, payload);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async removeRoleFromUser(host_id, user_id, role_id) {
    try {
      const {data} = await axios.delete(`/api/host/${host_id}/user/${user_id}/removeRole/${role_id}`);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

}

const hostService = new HostServiceClass()
export default hostService
